<template>
   <div>
        <vue-multi-select
            v-model="selectedValue"
            :options="listOption"
            :validationName="validationName"
            :scope="(scope != null) ? scope : null"
            :validation="validation"
            :name="name"
            :trackBy="trackBy"
            :label="label"
            :multiple="multiple"
            :customLabel="customLabel"
            :select="select"
            :remove="remove"
            :loading="useLoading"
            :disabled="disabled"
            :placeholder="placeholder"
            :valueLabel="valueLabel"
        >
        </vue-multi-select>
    </div>
</template>

<script>
import { RepositoryFactory } from "./../../repositories/RepositoryFactory";
const TujuanRujukanRepository = RepositoryFactory.get("tujuanRujukan");
import VueMultiSelect from './VueMultiSelect.vue';

export default {
    provide() {
        return {
            $validator: this.$validator
        };
    },
  inject: ["$validator"],
  components: {
      VueMultiSelect,
  },
  data() {
    return {
        dataResponse:[],
        localLoading: false,
    };
  },
  model: {
        prop: 'value',
        event: 'change'
  },
  props: {
    value: [Array, Number, String, Object],
    options: {
        type:Array
    },
    validationName:{
        type:String,
        default:'Poli'
    },
    validation:{
        type: [String, Object],
        default:''
    },
    name:{
        type:String,
        default: 'poli'
    },
    scope: {
        type: String,
        default:null
    },
    multiple:{
        type: Boolean,
        default: false
    },
    trackBy: {
        type: String,
        default: 'id'
    },
    label:{
        type: String,
        default: 'nama'
    },
    customLabel:{
        type:Function
    },
    select:{
        type: Function,
        default:() => {}
    },
    remove:{
        type: Function,
        default:() => {}
    },
    disabled:{
        type:Boolean,
        default:false
    },
    placeholder:{
        type: String
    },
    byDoktor: {
        type:Boolean,
        default: false
    },
    valueLabel:{
        type: String
    },
    loading: {
        type: Boolean,
        default: undefined
    }

  },
  computed: {
    selectedValue: {
        get () {
            return this.value
        },
        set (newValue) {
            this.$emit('change', newValue);
        }
    },
    listOption:function() {
        return (this.options != undefined) ? this.options : this.dataResponse
    },
    useLoading: function(){
        return (this.loading != undefined) ? this.loading : this.localLoading;
    }
  },
  methods: {
    async getData() {
        this.localLoading=true;
        new Promise((resolve, reject) => {
            if(!this.byDoktor) {
                TujuanRujukanRepository.get({}, resolve, reject );
            } else {
                TujuanRujukanRepository.getTujuanRujukanByDokter({}, resolve, reject );
            }
        }).then(response => {
            this.dataResponse = response.data;
            this.localLoading=false;
        }).catch(error => {
            var msg = (error.message) ? error.message : error;
            toastr.error('Poli : ' + msg);
        })
    },
  },
  mounted() {
    if (this.options == undefined) {
        this.getData();
    }
  }
};
</script>

