<template>
  <div
    :id="modalName"
    class="modal"
    role="dialog"
    aria-hidden="true"
    data-backdrop="static"
    data-keyboard="false"
  >
    <div class="modal-dialog modal-md modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ $t('module.unit') }}</h5>
        </div>
        <form @submit.prevent="submitData()">
          <div class="modal-body">
            <input
              type="hidden"
              class="form-control"
              v-model.number="unit.id"
            />
            <div class="form-group row">
              <label class="col-lg-4 col-form-label">{{ $t('common.displayName1') }}:</label>
              <div class="col-lg-6">
                <input
                  type="text"
                  class="form-control"
                  v-model="unit.displayName1"
                  v-validate="'required'"
                  data-vv-as="Display Name 1"
                  name="displayName1"
                  :class="{'is-invalid': errors.has('displayName1') }"
                />
                <div
                  v-show="errors.first('displayName1')"
                  class="invalid-feedback"
                >{{ errors.first('displayName1') }}</div>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-lg-4 col-form-label">{{ $t('common.displayName2') }}:</label>
              <div class="col-lg-6">
                <input
                  type="text"
                  class="form-control"
                  v-model="unit.displayName2"
                />
              </div>
            </div>
            <div class="form-group row">
              <label class="col-lg-4 col-form-label">{{ $t('common.tts') }}:</label>
              <div class="col-lg-6">
                <input
                  type="text"
                  class="form-control"
                  v-model="unit.textToSpeech"
                />
              </div>
              <div class="col-1">
                <button
                  type="button"
                  title="Bunyikan"
                  class="btn btn-secondary btn-icon"
                  @click="speakTts"
                ><i :class="[ speechState ? 'flaticon2-speaker' : 'la la-play' ]"></i></button>
              </div>
            </div>
            <div class="form-group row">
              <label
                for="example-text-input"
                class="col-4 col-form-label"
              >{{ $t('common.waktuTunggu') }}</label>
              <div class="col-lg-4">
                <div class="input-group">
                  <input
                    type="number"
                    min="0"
                    class="form-control"
                    v-model.number="unit.averageWaitTimeMinute"
                    v-validate="'required'"
                    data-vv-as="Waktu Tunggu"
                    name="averageWaitTimeMinute"
                    :class="{'is-invalid': errors.has('averageWaitTimeMinute') }"
                  />
                  <div class="input-group-append">
                    <span class="input-group-text">menit</span>
                  </div>
                  <div
                    v-show="errors.first('averageWaitTimeMinute')"
                    class="invalid-feedback"
                  >{{ errors.first('averageWaitTimeMinute') }}</div>
                </div>
              </div>
            </div>
            <div class="form-group row">
              <label
                for="example-text-input"
                class="col-4 col-form-label"
              >Poli:</label>
              <div class="col-lg-6">
                <poli-select
                    v-model="tujuanRujukan"
                ></poli-select>
                <!-- <tujuan-rujukan
                  ref="tujuanRujukanVue"
                  :changedValue.sync="tujuanRujukan.id"
                  id-select-picker="tujuan_rujukan_selectpicker"
                  :selected-picker="tujuanRujukan.id"
                  :is-validate="false"
                ></tujuan-rujukan> -->
              </div>
            </div>
            <div class="form-group row">
              <label
                for="example-text-input"
                class="col-4 col-form-label"
              >{{ $t('common.pilihan') }}:</label>
              <div class="col-lg-6">
                <div class="kt-checkbox-inline">
                  <label class="kt-checkbox">
                    <input
                      type="checkbox"
                      v-model="unit.displayInKiosk"
                    /> Tampilkan di kiosk
                    <span></span>
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-clean btn-bold btn-upper btn-font-md"
              data-dismiss="modal"
              @click="clearData()"
            >{{ $t('button.close') }}</button>
            <button
              type="submit"
              class="btn btn-default btn-bold btn-upper btn-font-md"
            >{{ $t('button.save') }}</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import PoliSelect from "../../_select/Poli.vue";
import SweetAlertTemplate from "../../_general/SweetAlert";
import BlockUI from "../../_js/BlockUI";
import Unit from "../../../model/unit-model";
const blockUI = new BlockUI();
import Speech from 'speak-tts';
const speech = new Speech();
import { RepositoryFactory } from "../../../repositories/RepositoryFactory";
const UnitRepository = RepositoryFactory.get("unit");
const SweetAlert = Vue.extend(SweetAlertTemplate);

export default {
  components: {
    PoliSelect
  },
  data() {
    return {
      unit: new Unit(),
      tujuanRujukan: null,
      speechState: false
    };
  },
  props: {
    modalName: { type: String },
    modalData: { type: Object }
  },
  methods: {
    speakTts: function () {
      var vx = this;
      speech.init({
        'volume': 1,
        'lang': 'id-ID',
        'rate': 0.5,
        'pitch': 2,
        'voice': 'Google Bahasa Indonesia',
        'splitSentences': true,
        'listeners': {
          'onvoiceschanged': (voices) => {
            console.log("Event voiceschanged", voices)
          }
        }
      });

      speech.init().then((data) => {
        speech.speak({
          text: 'Antrian:' + vx.unit.textToSpeech + ', 0 0 3, ke, counter 1',
          listeners: {
            onstart: () => {
              vx.speechState = true;
            },
            onend: () => {
              vx.speechState = false;
            },
            onresume: () => {
              vx.speechState = true;
            }
          }
        }).then(() => {
        }).catch(e => {
        })
      }).catch(e => {
      })
    },
    updateData: function (data) {
      
      this.tujuanRujukan = (data.tujuanRujukan) ? data.tujuanRujukan : null;
      
      var unitData = JSON.parse(JSON.stringify(data));
      this.unit = unitData;
    },
    clearData: function () {
      $("#" + this.modalName).modal("hide");
      this.$emit("update:showModal", false);
    },
    submitData: function () {
      var vx = this;

      this.$validator.validateAll().then(result => {
        if (result) {

          this.unit.tujuanRujukan = this.tujuanRujukan;

          if (this.unit.id) {
            let instance = new SweetAlert().showConfirm({
              swalTitle: "Apakah Anda yakin?",
              swalText: "Perubahan data Unit Antrian akan tersimpan.",
              swalType: "info",
              onConfirmButton: function () {
                blockUI.blockModal();
                vx.update(vx.unit);
              },
              onCancelButton: function () {
                blockUI.unblockModal();
              }
            });
          } else {
            let instance = new SweetAlert().showConfirm({
              swalTitle: "Apakah Anda yakin?",
              swalText: "Data Unit Antrian akan ditambahkan.",
              swalType: "info",
              onConfirmButton: function () {
                blockUI.blockModal();
                vx.create(vx.unit);
              },
              onCancelButton: function () {
                blockUI.unblockModal();
              }
            });
          }
        }
      });
    },
    async update(payload) {
      var vx = this;

      await UnitRepository.updateUnit(payload)
        .then(response => {
          new Promise((resolve, reject) => {
            vx.$emit("event", resolve);
          }).then((response) => {
            let instance = new SweetAlert().showInfo({
              swalTitle: "Berhasil!",
              swalText: "Perubahan data Unit Antrian berhasil disimpan.",
              swalType: "success",
              onConfirmButton: function () {
                blockUI.unblockModal();
                vx.clearData();
              }
            });
          });
        })
        .catch(error => {
          let instance = new SweetAlert().showInfo({
            swalTitle: "Gagal!",
            swalText: "Perubahan data Unit Antrian gagal disimpan.",
            swalType: "error",
            onConfirmButton: function () {
              blockUI.unblockModal();
            }
          });
        });
    },
    async create(payload) {
      var vx = this;

      await UnitRepository.createUnit(payload)
        .then(response => {
          new Promise((resolve, reject) => {
            vx.$emit("event", resolve);
          }).then((response) => {
            let instance = new SweetAlert().showInfo({
              swalTitle: "Berhasil!",
              swalText: "Data Unit berhasil ditambahkan.",
              swalType: "success",
              onConfirmButton: function () {
                blockUI.unblockModal();
                vx.clearData();
              }
            });
          });
        })
        .catch(error => {
          let instance = new SweetAlert().showInfo({
            swalTitle: "Gagal!",
            swalText: "Data Unit gagal ditambahkan.",
            swalType: "error",
            onConfirmButton: function () {
              blockUI.unblockModal();
            }
          });
        });
    }
  },
  mounted() {
    $("#" + this.modalName).modal();
    if (this.modalData) {
      this.updateData(this.modalData);
    }
  }
};
</script>
