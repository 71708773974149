export default function Unit() {
    return {
        id: null,
        averageWaitTimeMinute: null,
        displayName1: '',
        displayName2: '',
        tujuanRujukan: null,
        displayInKiosk: false,
        textToSpeech: null
    };
}
